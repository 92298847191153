<template>
  <div class="app-sidebar app-sidebar--dark" :class="sidebarbg">
    <!-- <div class="app-sidebar--header ml-4">
      <user-box v-if="authUser" :user="authUser" />
    </div> -->

    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation">
        <sidebar-menu showOneChild :menu="menu" @item-click="onItemClick" />
      </div>
    </VuePerfectScrollbar>

    <div class="bg-light toggle-sidebar-mobile pl-3" style="height: 200px">
      <!-- <b-button
        v-if="authUser"
        variant="link"
        target="_blank"
        class="text-left"
        href="https://d10a8qjc50njp0.cloudfront.net/misc/%E0%B9%83%E0%B8%9A%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81%E0%B8%8A%E0%B8%A1%E0%B8%A3%E0%B8%A1+D.A.R.E.+%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2.pdf"
        >ใบสมัครสมาชิกชมรม D.A.R.E. ประเทศไทย</b-button
      > -->
      <b-button
        v-if="authUser"
        variant="link"
        target="_blank"
        class="text-left"
        href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
        >คู่มือการใช้งานระบบ</b-button
      >

      <router-link
        v-if="authUser"
        class="d-block ml-4 py-2"
        active-class="active"
        to="/tutorials"
        exact
      >
        วีดีโอสอนการใช้งานระบบ
      </router-link>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { SidebarMenu } from "vue-sidebar-menu";
import { Auth } from "../../models";
// import UserBox from "../sidebar/UserBox";

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar,
    // UserBox,
  },
  props: {
    sidebarbg: String,
  },
  data() {
    return {
      // menu: [
      //   {
      //     title: "หน้าหลัก",
      //     icon: {
      //       element: "home-icon",
      //     },
      //     href: "/",
      //     //   // child: [
      //     //   //     {
      //     //   //         href: '/',
      //     //   //         title: 'Web analytics',
      //     //   //     },
      //     //   //     {
      //     //   //         href: '/dashboards/monitoring',
      //     //   //         title: 'Monitoring',
      //     //   //         class: 'pr-2',
      //     //   //         badge: {
      //     //   //             text: 'New',
      //     //   //             class: 'badge badge-first ml-auto order-2'
      //     //   //         }
      //     //   //     },
      //     //   // ]
      //   },
      //   {
      //     title: "ข้อมูลแผนการสอน",
      //     icon: {
      //       element: "book-icon",
      //     },
      //     href: "/lesson-plans",
      //   },
      //   {
      //     title: "ข้อมูลการสอน",
      //     icon: {
      //       element: "clipboard-icon",
      //     },
      //     href: "/classes",
      //   },
      // ],
      collapsed: false,
    };
  },
  computed: {
    authUser() {
      return Auth.user();
    },

    menu() {
      const baseMenu = [
        {
          title: "หน้าหลัก",
          icon: {
            element: "home-icon",
          },
          href: "/",
        },
        // {
        //   title: "ภาพรวมการสอน",
        //   icon: {
        //     element: "pie-chart-icon",
        //   },
        //   href: "/dashboard",
        //   hidden: !this.authUser.isAdminType,
        // },
        // {
        //   title: "ข้อมูลแผนการสอน",
        //   icon: {
        //     element: "book-icon",
        //   },
        //   href: "/lesson-plans",
        //   hidden: this.authUser.isExecutive,
        // },
        // {
        //   title: "ข้อมูลการสอน",
        //   icon: {
        //     element: "clipboard-icon",
        //   },
        //   href: "/classes",
        // },
        // {
        //   href: "/transactions",
        //   title: "รายงานขอเบิกค่าตอบแทนการสอน",
        //   icon: {
        //     element: "file-text-icon",
        //   },
        // },
        // {
        //   href: "/certificate",
        //   title: "พิมพ์หนังสือรับรอง",
        //   icon: {
        //     element: "file-text-icon",
        //   },
        // },
        {
          title: "จัดการข้อมูล",
          icon: {
            element: "book-open-icon",
          },
          // hidden: !this.authUser.isAdminType || this.authUser.isExecutive,
          hidden: false,
          child: [
            {
              href: "/courses",
              title: "จัดการหลักสูตร",
              // hidden: false,
              hidden: false
            },
            {
              href: "/lesson-plans",
              title: "จัดการบทเรียน",
              hidden: false,
            },
            {
              href: "/master-data/tests",
              title: "จัดการข้อสอบ/แบบทดสอบ",
              hidden: false,
            },
            {
              href: "/master-data/administrators",
              title: "ข้อมูลผู้ใช้งาน",
              hidden: false,
            },
          ],
        },
        {
          title: "จัดการเว็บไซต์",
          icon: {
            element: "database-icon",
          },
          // hidden: !this.authUser.isAdminType,
          hidden: false,
          child: [
            // {
            //   href: "/report/overview",
            //   title: "แบนเนอร์และข่าวประชาสัมพันธ์",
            // },
            {
              href: "/master-data/banner-news",
              title: "แบนเนอร์และข่าวประชาสัมพันธ์",
            },
            // {
            //   href: "/report/transactions",
            //   title: "เอกสาร E-book",
            // },
            {
              href: "/master-data/surveys",
              title: "แบบประเมิน",
            },
          ],
        },
        {
          title: "รายงาน",
          icon: {
            element: "book-open-icon",
          },
          // hidden: !this.authUser.isAdminType,
          hidden: false,
          child: [
            // {
            //   href: "/report/overview",
            //   title: "รายงานภาพรวมแผนการสอน",
            // },
            // {
            //   href: "/report/progress",
            //   title: "รายงานสถานะแผนการสอน",
            // },
            // {
            //   href: "/report/transactions",
            //   title: "รายงานสรุปค่าสอนครู",
            // },
            {
              href: "/report/certificate",
              title: "รายงานสรุปผลการเรียน",
            },
            {
              href: "/master-data/students",
              title: "รายงานนักเรียนที่จบการศึกษา",
            },
          ],
        }
        // {
        //   title: "เอกสารประกอบการสอน",
        //   icon: {
        //     element: "book-open-icon",
        //   },
        //   href: "/lessons",
        // },
      ];

      // let additionalMenu = [];

      // if (this.authUser && this.authUser.isAdminType) {
      //   let child = [];

      //   child.push({
      //     href: "/master-data/schools",
      //     title: "การจัดการโรงเรียน",
      //     hidden: false,
      //   });

      //   if (this.authUser.isSuperAdmin) {
      //     child.push({
      //       href: "/master-data/administrators",
      //       title: "การจัดการผู้ดูแลระบบ",
      //     });
      //   }

      //   child.push({
      //     href: "/master-data/teachers",
      //     title: "การจัดการคุณครู",
      //   });

      //   child.push({
      //     href: "/master-data/students",
      //     title: "การจัดการนักเรียน",
      //   });
      //   // if (this.authUser.isSuperAdmin) {
      //   // }

      //   additionalMenu = [
      //     ...additionalMenu,
      //     {
      //       title: "ข้อมูลหลัก",
      //       icon: {
      //         element: "database-icon",
      //       },
      //       child,
      //     },
      //   ];
      // }

      return baseMenu;
    },

    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },

    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    onItemClick() {
      if (this.sidebarCollapsedMobile) {
        this.sidebarCollapsedMobile = false;
      }
    },
  },
};
</script>
