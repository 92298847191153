import { Model } from "@vuex-orm/core";

export class Video extends Model {
    static get entity() {
        return "videos"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "video_neme": this.string(""),
            "keyVdo": this.attr(null),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/video', { params });
                },

                findOne(id, params = {}) {
                    return this.get(`/video/${id}`, { params });
                },

                getUploadUrl(params = {}) {
                    return this.get(`/video/upload-url`, { params }, { save: false });
                },

                create(body) {
                    return this.post('/video', body);
                },

                destroy(id) {
                    return this.delete(`/video/${id}`, { delete: id });
                },
            }
        };
    }
}
