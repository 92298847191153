import { Model } from "@vuex-orm/core";

export class User extends Model {
    static get entity() {
        return "user_profiles"
    }

    static fields() {
        return {
            address: this.string(""),
            address_document: this.string(""),
            bd_date: this.attr(null),
            birth_date: this.attr(null),
            create_by: this.attr(null),
            createdAt: this.attr(null),
            email: this.attr(null),
            first_name: this.string(""),
            id: this.attr(null),
            id_card: this.attr(null),
            id_governmen: this.attr(null),
            last_name: this.string(""),
            mDivisionId: this.attr(null),
            mHeadquarterId: this.attr(null),
            mRankId: this.attr(null),
            m_rank: this.attr(null),
            mStationId: this.attr(null),
            model_no: this.string(""),
            model_year: this.string(""),
            passhash: this.string(""),
            phone_number: this.string(""),
            position_name: this.string(""),
            retire_year: this.string(""),
            update_by: this.attr(null),
            updatedAt: this.attr(null),
            userGroupId: this.attr(null),
            user_approve: this.string(""),
            user_name: this.string(""),
            user_status: this.string(""),
            user_type: this.string(null),
            sum_fiscal_year: this.attr(null),
            totalTime: this.number(0),
            teacher_dares: this.attr([]),
            total_time: this.number(0),
            medal: this.attr(null),
            admin_type: this.string(null),
        }
    }

    get isAdmin() {
        return this.user_type === "ADMIN";
    }

    get isSuperAdmin() {
        return this.user_type === "SUPER_ADMIN";
    }

    get isAdminType() {
        return this.isAdmin || this.isSuperAdmin;
    }

    get isExecutive() {
        return this.isAdmin
            && this.admin_type !== null
            && this.admin_type === "EXECUTIVE";
    }

    get isAdminAll() {
        return this.isAdmin
            && this.admin_type !== null
            && this.admin_type === "ADMIN_ALL";
    }

    get isAdminHeadQuarter() {
        return this.isAdmin
            && this.admin_type !== null
            && this.admin_type === "ADMIN_HEADQUARTER";
    }

    get isAdminDivision() {
        return this.isAdmin
            && this.admin_type !== null
            && this.admin_type === "ADMIN_DIVISION";
    }

    get isAdminStation() {
        return this.isAdmin
            && this.admin_type !== null
            && this.admin_type === "ADMIN_STATION";
    }

    get canAccessAllHeadQuarters() {
        return this.isExecutive ||
            this.isSuperAdmin ||
            this.isAdminAll;
    }

    get canAccessAllDivisions() {
        return this.isExecutive ||
            this.isSuperAdmin ||
            this.isAdminAll ||
            this.isAdminHeadQuarter;
    }

    get canAccessAllStations() {
        return this.isExecutive ||
            this.isSuperAdmin ||
            this.isAdminAll ||
            this.isAdminHeadQuarter ||
            this.isAdminDivision;
    }

    static get apiConfig() {
        return {
            actions: {
                async findAll(params = {}) {
                    return this.get(`/user_profiles`, { params });
                },

                async find(id) {
                    return this.get(`/user_profiles/${id}`);
                },

                async create(form) {
                    let promise;

                    promise = await this.post(`/user_profiles`, {
                        ...form
                    });

                    const { response_status, response_description, data } = promise.response.data;

                    if (response_status === "ERROR") {
                        throw response_description;
                    }

                    const { user_profiles } = data;

                    User.insert({
                        data: { ...user_profiles }
                    });

                    return promise;
                },

                async update(id, form) {
                    return this.put(`/user_profiles/${id}`, {
                        ...form
                    });
                },

                destroy(id) {
                    return this.delete(`/user_profiles/${id}`, { delete: id });
                },

                getDashboard(params = {}) {
                    return this.get(`user_profiles/getDashboard`, { params })
                },
            }
        };
    }
}
