import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import { Auth } from "../models/Auth";

Vue.use(Router);

const router = new Router({
    mode: "history",

    base: __dirname,

    scrollBehavior(to) {
        if (to.hash) {
            return { selector: to.hash, behavior: "smooth" };
        }

        return { x: 0, y: 0, behavior: "smooth" };
    },

    routes: [{
            path: "/survey-question",
            name: "SurveyQuestion",
            meta: {
                auth: false,
                isPublic: true,
                layout: "no",
            },
            component: () =>
                import ( /* webpackChunkName: "survey--index" */ "../pages/survey-question/index"),
        },

        {
            path: "/tutorials",
            name: "Tutorial",
            meta: {
                auth: false,
                isPublic: true,
                layout: "no",
            },
            component: () =>
                import ( /* webpackChunkName: "tutorial--index" */ "../pages/tutorial/index"),
        },

        {
            path: "/registers",
            name: "Register",
            meta: {
                auth: false,
                isPublic: true,
                layout: "no",
            },
            component: () =>
                import ( /* webpackChunkName: "register--index" */ "../pages/register/index"),
        },

        {
            path: "/registers/detail",
            name: "Detail",
            meta: {
                auth: false,
                isPublic: true,
                layout: "no",
            },
            component: () =>
                import ( /* webpackChunkName: "register--detail" */ "../pages/register/detail"),
        },

        {
            path: "/sign-in",
            name: "SignIn",
            meta: {
                auth: false,
                layout: "no"
            },
            component: () =>
                import ( /* webpackChunkName: "auth--signin" */ "../pages/authentication/sign-in"),
        },

        {
            path: "/maintenance",
            name: "Maintenance",
            meta: {
                layout: "no"
            },
            component: () =>
                import ( /* webpackChunkName: "maintenance" */ "../pages/maintenance"),
        },

        {
            path: "/",
            name: "Home",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "home" */ "../pages/home"),
        },

        {
            path: "/lesson-plans",
            name: "LessonPlan",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "lesson-plan--index" */ "../pages/lesson-plan/index"),
        },

        {
            path: "/tests",
            name: "Tests",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "lesson-plan--index" */ "../pages/tests/index"),
        },

        {
            path: "/classes",
            name: "Class",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "class--index" */ "../pages/class/index"),
        },

        {
            path: "/transactions",
            name: "Transection",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "transaction--index" */ "../pages/transection/index"),
        },

        {
            path: "/certificate",
            name: "Certificate",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "certificate--index" */ "../pages/certificate/index"),
        },

        {
            path: "/lessons",
            name: "Lesson",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "lesson--index" */ "../pages/lesson/index"),
        },

        {
            path: "/dashboard",
            name: "Dashboard",
            meta: {
                auth: true,
                admin: true,
                // superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "dashboard" */ "../pages/dashboard"),
        },

        {
            path: "/master-data/banner-news",
            name: "MasterBannerNews",
            meta: {
                auth: true,
                admin: true,
                superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--banner-news" */ "../pages/master-data/banner-news"),
        },

        {
            path: "/master-data/stations",
            name: "MasterStation",
            meta: {
                auth: true,
                admin: true,
                superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--station" */ "../pages/master-data/station"),
        },

        {
            path: "/master-data/schools",
            name: "MasterSchool",
            meta: {
                auth: true,
                admin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--school" */ "../pages/master-data/school"),
        },

        {
            path: "/master-data/administrators",
            name: "MasterAdministrator",
            meta: {
                auth: true,
                admin: true,
                // superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--administrator" */ "../pages/master-data/administrator"),
        },

        {
            path: "/master-data/teachers",
            name: "MasterDataTeacher",
            meta: {
                auth: true,
                admin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--teacher" */ "../pages/master-data/teacher"),
        },

        {
            path: "/master-data/students",
            name: "MasterDataStudent",
            meta: {
                auth: true,
                admin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--student" */ "../pages/master-data/student"),
        },

        {
            path: "/master-data/surveys",
            name: "MasterDataSurvey",
            meta: {
                auth: true,
                admin: true,
                superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--survey" */ "../pages/master-data/survey"),
        },

        {
            path: "/master-data/tests",
            name: "MasterDataTests",
            meta: {
                auth: true,
                admin: true,
                superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--survey" */ "../pages/master-data/tests"),
        },

        {
            path: "/master-data/registrations",
            name: "MasterDataRegistration",
            meta: {
                auth: true,
                admin: true,
                superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "master-data--registration" */ "../pages/master-data/registration"),
        },

        {
            path: "/report/transactions",
            name: "ReportTransaction",
            meta: {
                auth: true,
                admin: true,
                // superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "report--transaction" */ "../pages/report/transaction"),
        },

        {
            path: "/report/progress",
            name: "ReportProgress",
            meta: {
                auth: true,
                admin: true,
                // superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "report--progress" */ "../pages/report/progress"),
        },

        {
            path: "/report/overview",
            name: "ReportOverview",
            meta: {
                auth: true,
                admin: true,
                // superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "report--overview" */ "../pages/report/overview"),
        },

        {
            path: "/courses",
            name: "Course",
            meta: {
                auth: true
            },
            component: () =>
                import ( /* webpackChunkName: "lesson-plan--index" */ "../pages/course/index"),
        },

        {
            path: "/report/certificate",
            name: "ReportCertificate",
            meta: {
                auth: true,
                admin: true,
                // superAdmin: true
            },
            component: () =>
                import ( /* webpackChunkName: "report--certificate" */ "../pages/report/certificate"),
        },

        // otherwise redirect to home
        { path: "*", name: "home", redirect: "/" }
    ],
});

router.beforeEach(async(to, from, next) => {
    NProgress.start();
    NProgress.set(0.1);

    const { auth, admin, superAdmin, isPublic = false } = to.meta;

    const authToken = Auth.getAuthToken();

    if (auth === true && !authToken) {
        return next({ path: "/sign-in", query: { redirectUrl: to.path } });
        // if (!Vue.$cookies.isKey("user")) {
        //   return next({ path: "/login", query: { returnUrl: to.path } });
        // }

        // // check if route is restricted by role
        // if (authorize.length) {
        //   const userInfo = Vue.$cookies.get("user");
        //   const { roles } = userInfo;

        //   const checkIsUserAuthorize = roles.some((role) => {
        //     return authorize.includes(role);
        //   });

        //   if (!checkIsUserAuthorize) {
        //     // role not authorised so redirect to LU page
        //     next({ path: "/" });
        //   }
        // }
    }

    if (admin === true || superAdmin === true) {
        await Auth.load();

        const authUser = Auth.user();

        if (admin === true && !authUser.isAdminType) {
            return next({ path: "/" });
        }

        if (superAdmin === true && !authUser.isSuperAdmin) {
            return next({ path: "/" });
        }
    }

    if (auth === false && authToken && !isPublic) {
        return next({ path: "/" });
    }

    next();
});

router.afterEach(() => {
    setTimeout(() => NProgress.done(), 500);
});

export default router;