import { Model } from "@vuex-orm/core";

export class Lesson extends Model {
    static get entity() {
        return "lessons"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "lesson_name": this.attr(null),
            "lesson_code": this.string(""),
            "lesson_type": this.string(""),
            "lesson_watch_url": this.string(""),
            "lesson_header_img": this.string(""),
            "lesson_start_date": this.string(""),
            "lesson_start_time_hr": this.string(""),
            "lesson_start_time_min": this.string(""),
            "lesson_time_to_show_test_hr": this.attr(null),
            "lesson_time_to_show_test_min": this.attr(null),
            "lesson_detail": this.string(""),
            "lesson_order": this.attr(null),
            "lesson_status": this.string(""),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
        }
    }

    async getDownloadUrl() {
        const promise = await Lesson.api().getDownloadUrl(this.id);

        const { url } = promise.response.data;

        return url;
    }

    static get apiConfig() {
        return {
            actions: {
                getDownloadUrl(id, params = {}) {
                    return this.get(`/lessons/${id}/download-url`, { params }, { save: false });
                },

                findAll(params = {}) {
                    return this.get('/lessons', { params });
                },

                create(body) {
                    return this.post('/lessons', body);
                },

                update(id, body) {
                    return this.put(`/lessons/${id}`, body);
                },

                destroy(id) {
                    return this.delete(`/lessons/${id}`, { delete: id });
                }
            }
        };
    }
}